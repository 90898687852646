import { useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import './Table.css';
import data from '../data';

function PhoneBook() {
  const [filteredData, setFilteredData] = useState(data);
  const onChange = (e) => {
    setFilteredData(
      data.filter(function (person) {
        return (
          person.phone.includes(e.target.value.toLowerCase()) ||
          person.fullName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          person.department
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          person.workPosition
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          person.email.includes(e.target.value.toLowerCase())
        );
      })
    );
  };

  console.log('filteredData ', filteredData);

  const columns = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        const afullName = a.fullName.toLowerCase();
        const bfullName = b.fullName.toLowerCase();
        if (afullName < bfullName) {
          return -1;
        }
        if (afullName > bfullName) {
          return 1;
        }

        return 0;
      },
    },
    {
      title: 'Должность',
      dataIndex: 'workPosition',
      sorter: (a, b) => {
        const aworkPosition = a.workPosition.toLowerCase();
        const bworkPosition = b.workPosition.toLowerCase();
        if (aworkPosition < bworkPosition) {
          return -1;
        }
        if (aworkPosition > bworkPosition) {
          return 1;
        }

        return 0;
      },
    },
    {
      title: 'Подразделение',
      dataIndex: 'department',
      sorter: (a, b) => {
        const adepartment = a.department.toLowerCase();
        const bdepartment = b.department.toLowerCase();
        if (adepartment < bdepartment) {
          return -1;
        }
        if (adepartment > bdepartment) {
          return 1;
        }

        return 0;
      },
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      sorter: (a, b) => a.phone - b.phone,
    },
    {
      title: 'Почта',
      dataIndex: 'email',
      sorter: (a, b) => {
        const aemail = a.email.toLowerCase();
        const bemail = b.email.toLowerCase();
        if (aemail < bemail) {
          return -1;
        }
        if (aemail > bemail) {
          return 1;
        }

        return 0;
      },
    },
  ];
  return (
    <div>
      <input type="text" placeholder="Введите запрос" onChange={onChange} />
      <Table dataSource={filteredData} columns={columns} />
    </div>
  );
}

export default PhoneBook;
